import './styles/NoteInsightsIntro.css';

import { RevealInsightsButton } from '../components/buttons/NoteInsightsButtons';
import btnMod from '../sharedStyles/button.module.css';

function NoteInsightsIntro({ loading, visualizeNotesData }) {
	return (
		<div className="note-insights-intro">
			<div className="note-insights-main">
				<h1 className="note-insights-intro__heading">
					{'Discover the hidden networks within your thoughts.'}
				</h1>
				<div>
					<RevealInsightsButton
						className={`${btnMod.btn} ${btnMod.btnStyled} ${btnMod.btnMisc} ${
							loading ? 'loading' : ''
						}`}
						onClick={visualizeNotesData}
						loading={loading}
					/>
				</div>
			</div>
			<div className="note-insights-intro__footer">
				<p className="note-insights-intro__instructions">
					{
						'Note: Please make sure to have at least three notes saved in your collection.'
					}
				</p>
			</div>
		</div>
	);
}

export default NoteInsightsIntro;
